import React, { useEffect } from "react";
import { CloseIcon } from "../../assets/Icons";
import { toast } from "react-toastify";
import { useUserEmailApproveByAdminMutation } from "../../store/services/userService";

const UserEmailApprove = ({ setOpen, userDetail }) => {
  const [updateStatus, userApprove] = useUserEmailApproveByAdminMutation();
  const submitApproveUser = ({ id, email }) => {
    updateStatus({
      id: id,
      email: email,
    });
  };
  useEffect(() => {
    if (userApprove?.isSuccess) {
      setOpen(false);
      toast.success(
        "Selected user email approved successfully and notified on user email!",
        {
          position: "top-center",
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }, [userApprove?.isSuccess]);
  return (
    <div className="confirmation-model flex">
      <div className="model-block flex flex-col">
        <div className="model-hdr flex items-center justify-end mb-3">
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="desc flex items-center justify-center">
          <p>Are you sure to Approve "{userDetail.name}" Email?</p>
        </div>
        <div className="actions flex items-center justify-center">
          <button className="btn button" onClick={(e) => setOpen(false)}>
            Cancel
          </button>
          <button
            className="btn button"
            onClick={(e) =>
              submitApproveUser({ id: userDetail._id, email: userDetail.email })
            }
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserEmailApprove;
