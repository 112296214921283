import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { CopyToClipboard, CheckIcon } from "react-copy-to-clipboard";

import {
  useGetUsersListQuery,
  useUserApproveMutation,
  useUserDeleteMutation,
  useUserEmailApproveByAdminMutation,
} from "../../store/services/userService";
import {
  DetailIcon,
  DeleteIcon,
  EditIcon,
  CopyIcon,
  CopiedIcon,
  CheckBoxIcon,
} from "../../assets/Icons";

import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import Wrapper from "../../routes/Wrapper";
import UserDetail from "../../components/UserDetail";
import VerifyDelete from "../../components/VerifyModel/VerifyDelete";
import VerifyApprove from "../../components/VerifyModel/VerifyApprove";
import UserEmailApprove from "../../components/VerifyModel/UserEmailApprove";
import Toast from "../../components/Toast";

const Main = () => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openApproveModel, setOpenApproveModel] = useState(false);
  const [openEmailApproveModel, setOpenEmailApproveModel] = useState(false);
  const { data, isLoading, isFetching } = useGetUsersListQuery();

  const [userDetail, setUserDetail] = useState();

  const renderColumns = () => {
    return [
      {
        name: "Name",
        sortable: true,
        selector: (row) => row?.name,
        grow: 2,
      },
      {
        name: "Email",
        sortable: true,
        selector: (row) => row?.email,
        grow: 2,
      },

      {
        name: (
          <>
            Usage Tokens %
            <br />
            Total Tokens
          </>
        ),
        sortable: true,
        grow: 1.8,
        cell: (row) => (
          <div>
            <div>
              {(
                (row?.totalUsageTokens / row?.totalTokensLimitDemo) *
                100
              ).toFixed(2)}
              %
            </div>
            <div className="flex">
              {row.totalUsageTokens} / <div> {row.totalTokensLimitDemo}</div>
            </div>
          </div>
        ),
      },
      {
        name: "Email-verified",
        sortable: true,
        grow: 1.6,
        cell: (row) => (
          <div>
            {row.is_verified === 1 && (
              <span className="status-tag success">Verified</span>
            )}
            {row.is_verified === 0 && (
              <span className="status-tag secondary">Non-Verified</span>
            )}
          </div>
        ),
      },
      {
        name: "Admin Approve",
        sortable: true,
        grow: 1.6,
        cell: (row) => (
          <div>
            {row.admin_verified ? (
              <span className="status-tag success">Verified</span>
            ) : (
              <button
                className="btn-approve button"
                onClick={(e) => {
                  setUserDetail(row);
                  setOpenApproveModel(true);
                }}
              >
                Approve
              </button>
            )}
          </div>
        ),
      },

      {
        name: "Actions",
        sortable: true,
        grow: 1.6,
        cell: (row) => (
          <div>
            <div className="flex items-center justify-center">
              <div
                className="icon flex items-center justify-center cursor-pointer"
                onClick={(e) => {
                  setOpen(true);
                  setUserDetail(row);
                }}
              >
                <DetailIcon />
              </div>
              <CopyToClipboard text={row.email}>
                <div
                  className="icon flex items-center justify-center cursor-pointer ml-3 relative"
                  onClick={(e) => setCopied(true)}
                >
                  <CopyIcon />
                  <Toast message={"Email Copied"} showToast={copied} />
                </div>
              </CopyToClipboard>
              <>
                {row.is_verified === 0 && (
                  <div
                    className="icon flex items-center justify-center cursor-pointer ml-3"
                    onClick={(e) => {
                      setUserDetail(row);
                      setOpenEmailApproveModel(true);
                    }}
                  >
                    <CheckBoxIcon />
                  </div>
                )}
              </>
              {!row.admin && (
                <div
                  className="icon flex items-center justify-center cursor-pointer ml-3"
                  onClick={(e) => {
                    setOpenDeleteModel(true);
                    setUserDetail(row);
                  }}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          </div>
        ),
      },
    ];
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
      // const timer = setTimeout(() => {
      //   setCopied(false);
      // }, 1000);
      // return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Wrapper>
      <div className="lading-page bg-themeColor2 h-screen flex">
        <div className="wrap wrapWidth flex flex-col">
          <div className="page-title">Users List</div>
          <div className="table-blk flex flex-col">
            <div className="tbl flex flex-col">
              <>
                <DataTable
                  columns={renderColumns()}
                  data={data?.users}
                  responsive={true}
                  pagination={true}
                />
              </>
              {isLoading || isFetching ? <Loader /> : <></>}
            </div>
          </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <UserDetail setOpen={setOpen} userDetail={userDetail} />
        </Modal>
        <Modal open={openDeleteModel} onClose={() => setOpenDeleteModel(false)}>
          <VerifyDelete setOpen={setOpenDeleteModel} userDetail={userDetail} />
        </Modal>
        <Modal
          open={openApproveModel}
          onClose={() => setOpenApproveModel(false)}
        >
          <VerifyApprove
            setOpen={setOpenApproveModel}
            userDetail={userDetail}
          />
        </Modal>

        <Modal
          open={openEmailApproveModel}
          onClose={() => setOpenEmailApproveModel(false)}
        >
          <UserEmailApprove
            setOpen={setOpenEmailApproveModel}
            userDetail={userDetail}
          />
        </Modal>
      </div>
    </Wrapper>
  );
};

export default Main;
