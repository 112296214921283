import React from "react";
import { CloseIcon } from "../../assets/Icons";

const UserDetail = ({ setOpen, userDetail }) => {
  return (
    <div className="table-row-model flex">
      <div className="model-block flex flex-col">
        <div className="model-hdr flex items-center justify-end mb-3">
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="model-item flex justify-start items-start">
          <div className="model-tag">Name</div>
          <div className="model-val">{userDetail.name}</div>
        </div>

        <div className="model-item flex justify-start items-start">
          <div className="model-tag">Email</div>
          <div className="model-val">{userDetail.email}</div>
        </div>

        <div className="model-item flex justify-start items-start">
          <div className="model-tag">Company</div>
          <div className="model-val">
            {userDetail.companyName ? userDetail.companyName : "-"}
          </div>
        </div>

        <div className="model-item flex justify-start items-start">
          <div className="model-tag">Company Type</div>
          <div className="model-val">
            <div className="model-val">
              {userDetail.companyType ? userDetail.companyType : "-"}
            </div>
          </div>
        </div>

        <div className="model-item flex justify-start items-start">
          <div className="model-tag">Company website</div>
          <div className="model-val">
            <div className="model-val">
              {userDetail.companyURL ? userDetail.companyURL : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
