import React, { useEffect } from "react";
import { CloseIcon } from "../../assets/Icons";
import { toast } from "react-toastify";
import { useUserDeleteMutation } from "../../store/services/userService";

const VerifyDelete = ({ setOpen, userDetail }) => {
  const [userDelete, deleteResp] = useUserDeleteMutation();
  const submitDeleteUser = ({ email }) => {
    userDelete({
      email,
    });
  };
  useEffect(() => {
    if (deleteResp?.isSuccess) {
      setOpen(false);
      toast.success("Selected user Deleted successfully!", {
        position: "top-center",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [deleteResp?.isSuccess]);
  return (
    <div className="confirmation-model flex">
      <div className="model-block flex flex-col">
        <div className="model-hdr flex items-center justify-end mb-3">
          <div
            className="cross-icon flex items-center justify-center cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="desc flex items-center justify-center">
          <p>
            Are you sure to delete "{userDetail?.name ? userDetail.name : ""}" ?
          </p>
        </div>
        <div className="actions flex items-center justify-center">
          <button className="btn button" onClick={(e) => setOpen(false)}>
            Cancel
          </button>
          <button
            className="btn button"
            onClick={(e) => submitDeleteUser({ email: userDetail.email })}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyDelete;
