import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userService = createApi({
  reducerPath: "users",
  tagTypes: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      userApprove: builder.mutation({
        query: (data) => {
          return {
            url: "api/verifyByAdmin",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),
      userEmailApproveByAdmin: builder.mutation({
        query: (data) => {
          return {
            url: "api/verifyEmailByAdmin",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),

      userDelete: builder.mutation({
        query: (data) => {
          return {
            url: "api/deleteUser",
            method: "DELETE",
            body: data,
          };
        },
        invalidatesTags: ["user"],
      }),

      getUsersList: builder.query({
        query: () => {
          return {
            url: "api/usersList",
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),

      getUserData: builder.query({
        query: (id) => {
          return {
            url: `api/user/${id}`,
            method: "GET",
          };
        },
        providesTags: ["user"],
      }),
    };
  },
});
export const {
  useUserApproveMutation,
  useUserEmailApproveByAdminMutation,
  useUserDeleteMutation,
  useGetUsersListQuery,
  useGetUserDataQuery,
} = userService;
export default userService;
