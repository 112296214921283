import React from "react";

function DetailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#000"
        d="M16 16H2V2h7V0H2a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V9h-2v7zM11 0v2h3.59l-9.83 9.83 1.41 1.41L16 3.41V7h2V0h-7z"
      ></path>
    </svg>
  );
}

export default DetailIcon;
